import React from "react";

// CSS
import "./BioPage.css"

// import component in to team card to display data
const BioPage = (props) => {
  const { img, name, title, bio1, bio2, bio3, bio4, bio5, bio6, close } = props;
  return (
    <>
      <div className="bio-container container-padding">
        <div className="bio-img-wrapper left">
          <img src={img} alt="" className="bio-img" />
          <h5 className="bio-name uc">{name}</h5>
          <p className="bio-title uc">{title}</p>
          <button className="bio-close-button" onClick={close}>close</button>
        </div>
        <div className="right">
          <p>{bio1}</p>
          <p>{bio2}</p>
          <p>{bio3}</p>
          <p>{bio4}</p>
          <p>{bio5}</p>
          <p>{bio6}</p>
        </div>
      </div>
    </>
  );
};

export default BioPage;
