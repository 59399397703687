import React from "react";

// CSS
import "./TargetedAssets.css";

// images
import cellTowerImg from "../../assets/images/TARGET-IMG1.jpg";
import dataCenterImg from "../../assets/images/TARGET-IMG2.jpg";
import fiberImg from "../../assets/images/TARGET-IMG3.jpg";
import easementsImg from "../../assets/images/TARGET-IMG4.jpg";

const TargetedAssets = () => {
  return (
    <>
      <div className="section-container l-gray-3-bg" id="targeted-assets">
        <div className="container-padding">
          <h1 className="section-title-slim">Targeted Assets</h1>
          <p className="o-black">
            Strategic Wireless Infrastructure Fund II is focused on acquiring
            essential digital infrastructure assets that support the devices and
            applications we use to stay connected to essential data, people,
            communities and businesses. ​
          </p>
          <div className="ta-content-container">
            <div className="ta-content-column-wrapper">
              <div className="ta-img-container">
                <img src={cellTowerImg} alt="Avatar" className="ta-image" />
                <div className="ta-overlay">
                  <div className="ta-text ta-small-text">
                    Elevated structures designed to support tenants’ equipment
                    that typically provides wireless connectivity as part of an
                    overall wireless network.​
                  </div>
                </div>
              </div>
              <div className="uc ta-box-titles">cell towers</div>
            </div>
            <div className="ta-content-column-wrapper">
              <div className="ta-img-container">
                <img src={dataCenterImg} alt="Avatar" className="ta-image" />
                <div className="ta-overlay">
                  <div className="ta-text ta-small-text">
                    Digital exchange centers house specialized computing and networking
                    equipment for the purpose of collecting, storing,
                    processing, and distributing data.
                  </div>
                </div>
              </div>
              <div className="uc ta-box-titles">
                digital exchange centers
              </div>
            </div>
            <div className="ta-content-column-wrapper">
              <div className="ta-img-container">
                <img src={fiberImg} alt="Avatar" className="ta-image" />
                <div className="ta-overlay">
                  <div className="ta-text ta-small-text">
                    Designed for high-performance data networking, and
                    telecommunications to support much of the world's internet,
                    cable television and telephone systems.​
                  </div>
                </div>
              </div>
              <div className="uc ta-box-titles">fiber</div>
            </div>
            <div className="ta-content-column-wrapper">
              <div className="ta-img-container">
                <img src={easementsImg} alt="Avatar" className="ta-image" />
                <div className="ta-overlay">
                  <div className="ta-text ta-small-text">
                    Acquiring the right to use and lease a specific area
                    surrounding a wireless site – typically at the base of a
                    cell tower or on a rooftop.
                  </div>
                </div>
              </div>
              <div className="uc ta-box-titles">easements</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TargetedAssets;
