import React from "react";

import "./StartInvesting.css";

import mailImg from "../../assets/images/paperwork.png";

const StartInvesting = () => {
  return (
    <>
      <div className="section-container l-gray-3-bg" id="start-investing">
        <div className="container-padding">
          <h1 className="section-title-slim no-margin">Ready to invest?</h1>
          <div className="t-center startinvesting-content-container">
            <img src={mailImg} alt="envelope" className="startinvesting-img" />
            <p className="startinvesting-p d-gray-4">
              Simply work with your financial advisor to complete and submit the
              fund’s subscription agreement. ​
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartInvesting;
