import React, { useState } from "react";
import axios from "axios";

// Components
import Modal from "../modal/Modal";

// CSS
import "./CloserLook.css";

// images
import thumbnail from "../../assets/images/SW20009-THUMB.jpg";

const CloserLook = () => {
  const ROOT_URL = process.env.REACT_APP_API_URL;

  const [modalShow, setModalShow] = useState(false); // Controls the modal show/hide functionality with a simple boolean.
  const [fitemId, setFitemId] = useState("");
  const [itemPath, setItemPath] = useState("");

  const itemPathRequest = (sku) => {
    let private_placement_id = parseInt(
      sessionStorage.getItem("privatePlacementId"),
      10
    );
    let product_id = sessionStorage.getItem("productId");
    let investor_mode = sessionStorage.getItem("investorMode");
    let profile_id = parseInt(sessionStorage.getItem("profileId"), 10);
    let item_sku = sku;
    let view_only = sessionStorage.getItem("viewOnly");
    let requestData = {
      item_sku: item_sku,
      investor_mode: investor_mode,
      view_only: view_only,
      product_id: product_id,
      private_placement_id: private_placement_id,
      profile_id: profile_id,
    };
    let spRequest = {
      spName: "InvestorPortal_SelectFItem",
      jsonInput: JSON.stringify(requestData),
    };
    let spRequestData = JSON.stringify(spRequest);
    let authenticationToken = sessionStorage.getItem("token");

    axios({
      method: "post",
      url: ROOT_URL,
      data: spRequestData,
      headers: {
        "Content-Type": "application/json",
        Authorization: authenticationToken,
      },
    })
      .then((response) => {
        const success_response = JSON.parse(response.data);
        // console.log(success_response.Rows[0]);
        // const sku = success_response.Rows[0].item_sku;
        const url = success_response.Rows[0].download_url;
        const isHosted = success_response.Rows[0].hosted_video;
        const path = success_response.Rows[0].document_path;
        const fItemId = success_response.Rows[0].fitem_id;

        // setModalSku(sku);
        setItemPath(path);
        setFitemId(fItemId);
        if (isHosted) {
          setModalShow(true);
        } else if (isHosted === false) {
          window.open(url);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  // this runs on the close button on the modal. We have to set modalFitem to ('') or else the video will keep playing when you close the modal.
  function renderClose() {
    setModalShow(false);
    setItemPath("");
    setFitemId("");
  }

  return (
    <>
      <div className="section-container l-gray-2-bg">
        <h1 className="section-title-slim">A Closer Look at Cell Towers</h1>
        <div className="closerlook-dg-wrapper">
          <div className="container-padding">
            <div className="closerlook-image-wrapper">
              <img
                src={thumbnail}
                alt="cell towers"
                className="closerlook-img"
                onClick={() => itemPathRequest("SW20009")}
              />
            </div>
          </div>
        </div>
        <Modal
          show={modalShow}
          close={renderClose}
          path={itemPath}
          fItemId={fitemId}
        />
      </div>
    </>
  );
};

export default CloserLook;
