import React, { useState } from "react";
import axios from "axios";

// Components
import Modal from "../modal/Modal";

// CSS
import "./whyConsider.css";

// Images
import thumbnailImg from "../../assets/images/SW20008-THUMB.jpg";

const WhyConsider = () => {
  const ROOT_URL = process.env.REACT_APP_API_URL;
  const baseVideoUrl = process.env.REACT_APP_SCAPI;
  const largeImgUrl = process.env.REACT_APP_LARGE_THUMBNAIL_URL;

  const [modalShow, setModalShow] = useState(false); // Controls the modal show/hide functionality with a simple boolean.
  const [fitemId, setFitemId] = useState("");
  const [itemPath, setItemPath] = useState("");

  const itemPathRequest = (sku) => {
    let private_placement_id = parseInt(
      sessionStorage.getItem("privatePlacementId"),
      10
    );
    let product_id = sessionStorage.getItem("productId");
    let investor_mode = sessionStorage.getItem("investorMode");
    let profile_id = parseInt(sessionStorage.getItem("profileId"), 10);
    let item_sku = sku;
    let view_only = sessionStorage.getItem("viewOnly");
    let requestData = {
      item_sku: item_sku,
      investor_mode: investor_mode,
      view_only: view_only,
      product_id: product_id,
      private_placement_id: private_placement_id,
      profile_id: profile_id,
    };
    let spRequest = {
      spName: "InvestorPortal_SelectFItem",
      jsonInput: JSON.stringify(requestData),
    };
    let spRequestData = JSON.stringify(spRequest);
    let authenticationToken = sessionStorage.getItem("token");

    axios({
      method: "post",
      url: ROOT_URL,
      data: spRequestData,
      headers: {
        "Content-Type": "application/json",
        Authorization: authenticationToken,
      },
    })
      .then((response) => {
        const success_response = JSON.parse(response.data);
        // console.log(success_response.Rows[0]);
        // const sku = success_response.Rows[0].item_sku;
        const url = success_response.Rows[0].download_url;
        const isHosted = success_response.Rows[0].hosted_video;
        const path = success_response.Rows[0].document_path;
        const fItemId = success_response.Rows[0].fitem_id;

        // setModalSku(sku);
        setItemPath(path);
        setFitemId(fItemId);
        if (isHosted) {
          setModalShow(true);
        } else if (isHosted === false) {
          window.open(url);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  // this runs on the close button on the modal. We have to set modalFitem to ('') or else the video will keep playing when you close the modal.
  function renderClose() {
    setModalShow(false);
    setItemPath("");
    setFitemId("");
  }
  return (
    <>
      <div
        className="why-section-bg section-container"
        id="why-should-you-consider-investing"
      >
        <h1 className="section-title-slim white">
          Why Should You Consider Investing?
        </h1>
        <p className="t-center container-padding why-p-margin white">
          Strategic Wireless Infrastructure Fund II CEO, Todd Rowley, discusses
          why he believes digital infrastructure is such an appealing investment
          opportunity. ​
        </p>
        <div className="why-video-wrapper">
          <div className="container-padding">
            <div className="why-video-container">
              <img
                src={thumbnailImg}
                alt=""
                className="full-width"
                // Test sku below
                onClick={() => itemPathRequest("SW20008")}
                // onClick={() => itemPathRequest("SW0287")} 
              />
            </div>
          </div>
        </div>
        <Modal
          show={modalShow}
          close={renderClose}
          path={itemPath}
          fItemId={fitemId}
        />
      </div>
    </>
  );
};

export default WhyConsider;
